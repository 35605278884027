import { el } from 'redom'
import InterfaceOriginal from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/Interface';
import Language from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/Language';

export default class Interface extends InterfaceOriginal
{
    buildStyle() {
        const element = super.buildStyle();
        const originalStyles = element.innerHTML;

        return el('style',
            originalStyles,
            `#cconsent-bar { --theme-background-color: ${window.CookieConsent.config.theme.barColor}}`
        );
    }

    buildBar() {
        const defaultElement = super.buildBar(arguments);
        const textElement = defaultElement.querySelector('.cc-text');
        const linksElement = defaultElement.querySelector('.ccb__links');
        const rightContainerElement = defaultElement.querySelector('.ccb__right');

        textElement.prepend(
            el(
                'h3.ccb__title',
                Language.getTranslation(
                    window.CookieConsent.config,
                    window.CookieConsent.config.language.current,
                    'barTitle'
                )
            )
        );

        rightContainerElement.appendChild(linksElement);

        return defaultElement;
    }
}
